import { React } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./main.css";

export function Main() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12} className="mt-5 mb-4">
          <h2 style={{fontSize: "4.0rem"}} className="mb-4">
            Be a localist.
          </h2> 

          <h2 style={{fontSize: "2.0rem"}}>
            {/* <b>PASSERBY</b> is a decentralized protocol for hyperlocal
            applications — like food delivery, ride hailing, and 
            online dating. It specializes in location-aware 
            discovery — that is, helping peers find resources
            based on geographic proximity — without requiring a 
            coordinating central authority. */}

            <b>PASSERBY</b> is the location layer for the decentralized 
            web. Discover resources based on geographic proximity.
            Build decentralized hyperlocal applications — like food delivery, 
            ride hailing, and online dating. Find out who and what is near any
            point on Earth — no coordinating central authority required.
          </h2>
        </Col>
      </Row>

      <Row>
        <Col xs={1} className="px-5" style={{fontSize: "2.0rem"}}>
          >>
        </Col>

        <Col xs={8} style={{fontSize: "2.0rem"}}>
          <a href="https://github.com/noahlevenson/passerby">GitHub</a>
        </Col>
      </Row>

      {/*<Row>
        <Col xs={1} className="px-5" style={{fontSize: "2.0rem"}}>
          >>
        </Col>

        <Col xs={8} md={10} style={{fontSize: "2.0rem"}}>
          <a href="">2-minute explainer video</a>
        </Col>
      </Row>*/}
    </Container>
  );
}