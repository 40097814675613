import React from 'react';
import { Main } from "./views/main/main";

function App() {
  return (
    <Main/>
  );
}

export default App;
